'use client';
import { useBreakpointValue } from '@chakra-ui/react';
import { useMemo } from 'react';

export function useIsHeaderMultiRow() {
  return useBreakpointValue({ base: true, md: false }, { fallback: 'md' });
}

export function useHeaderSize() {
  const isHeaderMultiRow = useIsHeaderMultiRow();
  return useMemo(() => (isHeaderMultiRow ? 112 : 64), [isHeaderMultiRow]);
}
