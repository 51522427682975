'use client';
import { useEffect } from 'react';
import { useFocusPlayer } from '../lib/hooks/focus-player';

export default function Index() {
  const { setFocusPlayer } = useFocusPlayer();
  useEffect(() => {
    setFocusPlayer(null);
  }, [setFocusPlayer]);
  return <></>;
}
