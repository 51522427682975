'use client';
import { Box } from '@chakra-ui/react';
import { useHeaderSize } from '../lib/hooks/use-header-size';

export default function FragmentLinkTarget({ id }: { id: string }) {
  const headerSize = useHeaderSize();
  return (
    <Box position="relative">
      <Box position="absolute" top={`-${headerSize}px`} id={id} />
    </Box>
  );
}
